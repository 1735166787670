<template>
  <div class="page-layout users">
     <b-alert
      :show="alertCountdown"
      dismissible
      fade
      :variant="alertType"
      @dismiss-count-down="countDownChanged"
    >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3><b>المدرسين</b></h3>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <div class="">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" id="dataTables-example">
                <thead>
                  <tr>
                    <th width="5%">
                      <span class="tbl-data">#</span>
                    </th>
                    <th>
                      <span class="tbl-data">الصورة</span>
                    </th>
                    <th>
                      <span class="tbl-data">الاسم</span>
                    </th>
                    <th>
                      <span class="tbl-data">اسم المستخدم</span>
                    </th>
                    <th>
                      <span class="tbl-data">البريد الالكتروني</span>
                    </th>
                    <th>
                      <span class="tbl-data">رقم المحمول</span>
                    </th>
                     <th>
                      <span class="tbl-data">حالة الحساب</span>
                    </th>
                    <th width="10%">
                      <span clas="tbl-data">
                        <b-button @click="goToRoute('addTeacher')" size="sm" class="btn btn-success add-admin-btn options-admin-btn" data-toggle="modal" data-target="#addAdmin" ><i class="fa fa-plus"></i></b-button>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no-data-row" v-if="users.length == 0 && isLoading">
                    <td colspan="8">
                      <div class="text-center >text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </td>
                  </tr>
                  <tr class="no-data-row" v-if="users.length == 0 && !isLoading">
                    <td colspan="8">
                      لا يوجد معلومات
                    </td>
                  </tr>
                  <tr v-for="(item, index) in users" :key="index">
                    <td>
                      {{++index}}
                    </td>
                    <td>
                      <b-avatar :src="item.image" class="ml-3"></b-avatar>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.full_name}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{(item.user_name == null) ? '-' : item.user_name}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.email}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.phone}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{approventDir[item.approvement]}}</span>
                    </td>
                    <td>
                      <div class="form-group row-option">
                        <b-button type="button" size="sm" class="btn btn-primary options-admin-btn edit-options-admin-btn" @click="goToEditRoute(item.id)"><i class="fa fa-edit"></i></b-button>
                        <b-button type="button" size="sm" class="btn btn-danger options-admin-btn del-options-admin-btn" data-toggle="modal" data-target="#delAdmin" @click="openDeleteItem(item.id);" ><i class="fa fa-trash"></i></b-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="delModal" centered title="حذف المدرس">
      <p class="my-4">؟هل تريد حذف هذا المدرس</p>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-left mr-1"  @click="deleteItem()">
            حذف
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="delModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import axios from 'axios';

  export default {
    name: 'Home',
    data() {
      return {
        users:[],
        isLoading: true,
        alertType: '',
        alertText: '',
        alertCountdown: 0,
        showDismissibleAlert: false,
        approventDir: {
          "under_revision": "تحت المراجعة",
          "accept": "مفتوح",
          "blocked":"مغلق"
        },
        options: [
          {
            value: "accept",
            text: "مفتوح"
          },
          {
            value: "under_revision",
            text: "تحت المراجعة"
          },
          {
            value: "blocked",
            text: "مغلق"
          }
        ],
        currentID: null,
        delModal: false
      }
    },
    methods: {
      loadData(){
        this.isLoading = true;
        axios.get(process.env.VUE_APP_API_URL + '/admin/teachers', {
        })
        .then((response) => {
          var data = response.data.data;
          this.users = data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      },
      delItem(item){
        this.delModal = true;
      },
      deleteItem() {
        if(this.currentID) {
          this.isLoading = true;
          axios.delete(process.env.VUE_APP_API_URL + '/admin/teachers/' + this.currentID, {
          })
          .then((response) => {
            this.alertType = "success"
            this.alertText = "تم حدف المدرس بنجاح "
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.delModal = false;
            this.loadData();
          })
          .catch((error) => {
            this.isLoading = false;
            this.alertType = "danger"
            this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.delModal = false;
          });
        }
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      openDeleteItem(id){
        if(id) {
          this.delModal = true;
          this.currentID = id;
        }
      },
      goToEditRoute(id){
        if(id) {
          this.$router.push({
            name: 'editTeacher',
            params: {
              id
            }
          })
        }
      },
      goToRoute(routeName) {
        this.$router.push({
          name: routeName
        })
      }
    },
    created() {
      this.loadData();
    },
  }
</script>
<style scoped>
  @import '../../../assets/css/tableContent.css'
</style>