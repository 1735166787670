<template>
  <div class="page-layout items">
     <b-alert
      :show="alertCountdown"
      dismissible
      fade
      :variant="alertType"
      @dismiss-count-down="countDownChanged"
    >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3><b>طلبات الانضمام للغرف</b></h3>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <div class="">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" id="dataTables-example">
                <thead>
                  <tr>
                    <th width="5%">
                      <span class="tbl-data">#</span>
                    </th>
                    <th>
                      <span class="tbl-data">الطلب</span>
                    </th>
                    <th width="10%">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no-data-row" v-if="items.length == 0 && isLoading">
                    <td colspan="10">
                      <div class="text-center >text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </td>
                  </tr>
                  <tr class="no-data-row" v-if="items.length == 0 && !isLoading">
                    <td colspan="10">
                      لا يوجد معلومات
                    </td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      {{++index}}
                    </td>
                    <td>
                      <span class="tbl-data">يريد {{item.student.full_name}} الانضمام الى غرفة {{item.room.name}}</span>
                    </td>
                    <td>
                      <div class="form-group row-option">
                        <b-button type="button" size="sm" class="btn btn-success options-admin-btn edit-options-admin-btn" @click="acceptJoinRequest(item)"><i class="fa fa-check"></i></b-button>
                        <b-button type="button" size="sm" class="btn btn-danger options-admin-btn del-options-admin-btn" data-toggle="modal" data-target="#delAdmin" @click="refuseJoinRequest(item);" ><i class="fa fa-times"></i></b-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="acceptModal" centered title="قبول الانضمام">
      <p>هل تريد قبول الطالب للانضمام ؟</p>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-left mr-1"  @click="acceptRequest()">
            قبول
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="acceptModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="refuseModal" centered title="قبول الانضمام">
      <p>هل تريد رفض الطالب للانضمام ؟</p>
      <b-textarea v-model="blockreason" placeholder="سبب الرفض"></b-textarea>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-left mr-1"  @click="refuseRequest()">
            قبول
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="refuseModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import axios from 'axios';

  export default {
    name: 'Home',
    data() {
      return {
        items:[],
        isLoading: true,
        alertType: '',
        alertText: '',
        alertCountdown: 0,
        showDismissibleAlert: false,
        currentRequest: {},
        acceptModal: false,
        refuseModal: false,
        blockreason: null
      }
    },
    methods: {
      acceptJoinRequest(item) {
        this.currentRequest = item;
        this.acceptModal = true;
      },
      acceptRequest() {
        this.isLoading = true;
        axios.put(process.env.VUE_APP_API_URL + '/admin/change-join-request-status?student_id=' + this.currentRequest.student.id + '&roomId=' + this.currentRequest.room.id + '&status=accept', null)
        .then((response) => {
          this.alertType = "success"
          this.alertText = "تم قبول الطالب بنجاح"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.acceptModal = false;
          this.loadData();
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      },
      refuseJoinRequest(item) {
        this.currentRequest = item;
        this.blockreason = null;
        this.refuseModal = true;
      },
      refuseRequest() {
        this.isLoading = true;
        axios.put(process.env.VUE_APP_API_URL + '/admin/change-join-request-status?student_id=' + this.currentRequest.student.id + '&roomId=' + this.currentRequest.room.id + '&status=blocked&block_reason=' + this.blockreason, null)
        .then((response) => {
          this.alertType = "success"
          this.alertText = "تم رفض الطالب بنجاح"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.refuseModal = false;
          this.loadData();
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      },
      loadData(){
        this.isLoading = true;
        axios.get(process.env.VUE_APP_API_URL + '/admin/show-join-requests', {})
        .then((response) => {
          var data = [];
          response.data.data.forEach(element => {
            if(element.student && element.room) {
              data.push(element)
            }
          });
          this.items = data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
    },
    created() {
      this.loadData();
    },
  }
</script>
<style scoped>
  @import '../../../assets/css/tableContent.css'
</style>