<template>
  <div class="page-layout items">
     <b-alert
      :show="alertCountdown"
      dismissible
      fade
      :variant="alertType"
      @dismiss-count-down="countDownChanged"
    >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3><b>الملفات </b></h3>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <div class="">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" id="dataTables-example">
                <thead>
                  <tr>
                    <th width="5%">
                      <span class="tbl-data">#</span>
                    </th>
                    <th>
                      <span class="tbl-data">اسم الملف</span>
                    </th>
                    <th>
                      <span class="tbl-data">مسار الملف</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no-data-row" v-if="items.length == 0 && isLoading">
                    <td colspan="7">
                      <div class="text-center >text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </td>
                  </tr>
                  <tr class="no-data-row" v-if="items.length == 0 && !isLoading">
                    <td colspan="8">
                      لا يوجد معلومات
                    </td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      {{++index}}
                    </td>
                    <td>
                      <span class="tbl-data">{{item.name}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.path}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import { sendRequest }  from "../../../utils/RequestHelper.js"

  export default {
    name: 'Home',
    data() {
      return {
        items:[],
        isLoading: true,
        alertType: '',
        alertText: '',
        alertCountdown: 0,
        showDismissibleAlert: false,
        currentID: null,
        delModal: false,
        roomsDic: {}
      }
    },
    methods: {
      loadData(){
        this.isLoading = true;
        var successCallback = (response) => {
          var data = response.data.data;
          this.items = data;
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/admin/show-files/' + this.$route.params.roomID, false, {}, successCallback, errorCallback);
      },
      delItem(item){
        this.delModal = true;
      },
      deleteItem() {
        if(this.currentID) {
          this.isLoading = true;
          axios.delete(process.env.VUE_APP_API_URL + '/admin/filesrooms/' + this.currentID, {
          })
          .then((response) => {
            this.alertType = "success"
            this.alertText = "تم حدف ملفات الفصل بنجاح "
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.delModal = false;
            this.loadData();
          })
          .catch((error) => {
            this.isLoading = false;
            this.alertType = "danger"
            this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.delModal = false;
          });
        }
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      openDeleteItem(id){
        if(id) {
          this.delModal = true;
          this.currentID = id;
        }
      },
      goToEditRoute(id){
        if(id) {
          this.$router.push({
            name: 'editRoomFile',
            params: {
              id
            }
          })
        }
      },
      goToRoute(routeName) {
        this.$router.push({
          name: routeName
        })
      },
      loadRooms() {
        this.isLoading = true;
        this.roomsDic = {};
        var successCallback = (response) => {
          if(response.data.data.length > 0) {
            response.data.data.forEach(element => {
              if(!this.roomsDic[element.id])
                this.roomsDic[element.id] = element.name
            });
            this.loadData();
          }
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/admin/rooms', false, {}, successCallback, errorCallback);
      }
    },
    created() {
      this.loadRooms();
    },
  }
</script>
<style scoped>
  @import '../../../assets/css/tableContent.css'
</style>