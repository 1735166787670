<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div>
      <div class="text-center >text-danger my-2" v-if="isLoading">
        <b-spinner class="align-middle"></b-spinner>
      </div>
      <div class="row" v-if="!isLoading">
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="primary" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fas fa-video" style="color: #004094; font-size:1.5rem"></i>
              </b-avatar>
              <span class="ml-auto"><h5>{{data.lesson_counter}} درس</h5></span>
            </b-list-group-item>
          </b-media>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="light" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fas fa-user-graduate" style="color: #908182; font-size:1.5rem"></i>
              </b-avatar>
              <span class="ml-auto"><h5>{{data.students_counter}} طالب</h5></span>
            </b-list-group-item>
          </b-media>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="success" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fas fa-chalkboard-teacher" style="color: #4b5724; font-size:1.5rem"></i>
              </b-avatar>
              <span class="ml-auto"><h5>{{data.teachers_counter}} مدرس</h5></span>
            </b-list-group-item>
          </b-media>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="danger" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fas fa-chalkboard" style="color: #9a1c24; font-size:1.5rem"></i>
              </b-avatar>
              <span class="ml-auto"><h5>{{data.room_counter}} فصل</h5></span>
            </b-list-group-item>
          </b-media>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="warning" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fas fa-chalkboard" style="color: #85643c; font-size:1.5rem"></i>
              </b-avatar>
              <span class="ml-auto"><h5>{{data.public_room_counter}} فصل عام</h5></span>
            </b-list-group-item>
          </b-media>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="secondary" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fas fa-chalkboard" style="color: #435460; font-size:1.5rem"></i>
              </b-avatar>
              <span class="ml-auto"><h5>{{data.private_room_counter}} فصل خاص</h5></span>
            </b-list-group-item>
          </b-media>
        </div>
      </div>
    </div>
 </div>
</template>

<script>
  import store from "../store/index.js"
  import { sendRequest }  from "../utils/RequestHelper.js"

  export default {
    name: 'Home',
    data() {
      return {
        isLoading: false,
        data: {},
        alertCountdown: 0,
        alertType: '',
        alertText: ''
      }
    },
    methods: {
      loadData(){
        this.isLoading = true;
        var successCallback = (response) => {
          this.data = response.data.data;
          console.log(this.data);
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/admin/configration', false, {}, successCallback, errorCallback);
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
      else {
        this.loadData();
      }
    }
  }
</script>
