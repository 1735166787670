<template>
  <div class="page-layout users">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3><b>إضافة مدرس</b></h3>
    </div>
    <b-form @submit.stop.prevent="onSubmit">
      <b-container class="bv-example-row">
        <b-row>
          <b-col lg="6" md="12">
            <b-form-group id="group-teacher-add-full-name" label="الاسم" label-for="teacher-add-full-name">
              <b-form-input
                id="teacher-add-full-name"
                name="teacher-add-full-name"
                v-model="$v.form.full_name.$model"
                :state="validateState('full_name')"
                aria-describedby="input-add-full-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-full-name">
                الاسم مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-teacher-add-image" label="الصورة" label-for="teacher-add-image">
              <b-form-file
                type="file"
                id="teacher-add-image"
                name="teacher-add-image"
                v-model="$v.form.image.$model"
                :state="validateState('image')"
                aria-describedby="input-add-image"
              ></b-form-file>
              <b-form-invalid-feedback id="input-add-image">
                  الصورة مطلوبة
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-teacher-add-user-name" label="اسم المستخدم" label-for="teacher-add-user-name">
              <b-form-input
                id="teacher-add-user-name"
                name="teacher-add-user-name"
                v-model="$v.form.user_name.$model"
                :state="validateState('user_name')"
                aria-describedby="input-add-user-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-user-name"  v-if='!$v.form.user_name.required'>
                اسم المستخدم مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-teacher-add-email" label="البريد الاكتروني" label-for="teacher-add-email">
              <b-form-input
                id="teacher-add-email"
                name="teacher-add-email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="input-add-email"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-email" v-if='!$v.form.email.required'>
                البريد الالكتروني مطلوب
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-email" v-if='!$v.form.email.email'>
                البريد الالكتروني غير صالح - xxx@yyy.zzz
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-teacher-add-password" label="كلمة المرور" label-for="teacher-add-password">
              <b-form-input
                id="teacher-add-password"
                name="teacher-add-password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="input-add-password"
                type="password"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-password" v-if='!$v.form.password.required'>
                كلمة المرور مطلوبه
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-password" v-if='!$v.form.password.minLength'>
                كلمة المرور اكثر من 8 احرف
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-teacher-add-phone" label="رقم المحمول" label-for="teacher-add-phone">
              <b-form-input
                id="teacher-add-phone"
                name="teacher-add-phone"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                aria-describedby="input-add-phone"
                type="tel"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.required'>
                  رقم المحمول مطلوب
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.numeric'>
                  رقم المحمول غير صالح - يجب ان يحتوي على ارقام فقط
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.minLength || !$v.form.phone.maxLength '>
                  رقم المحمول غير صالح - يتكون من 11 رقما
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-teacher-add-approvement" label="حالة الحساب" label-for="teacher-add-approvement">
              <b-form-select
                id="teacher-add-approvement"
                name="teacher-add-approvement"
                v-model="$v.form.approvement.$model"
                :options="approvements"
                :state="validateState('approvement')"
                aria-describedby="input-add-approvement"
              ></b-form-select>
              <b-form-invalid-feedback id="input-add-approvement" v-if='!$v.form.approvement.required'>حالة الحساب مطلوبة</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary">اضافة مدرس</b-button>
        <b-button class="mr-2" @click="resetForm()">اعادة</b-button>
      </b-container>
      
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  mixins: [validationMixin],
  data() {
    return {
      alertCountdown: 0,
      alertType: '',
      alertText: '',
      approvements: [
        { value: null, text: "اختر..." },
        { value: 'accept', text: "مفتوح" },
        { value: 'under_revision', text: "تحت المراجعة" },
        { value: 'blocked', text: "مغلق" },
      ],
      form: {
        full_name: null,
        user_name: null,
        email: null,
        password: null,
        phone: null,
        approvement: null,
        block_reason: null,
        image: null
      }
    };
  },
  validations: {
    form: {
      full_name: {
        required
      },
      user_name: {
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      image: {
      },
      phone: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11)
      },
      approvement: {
        required
      },
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    countDownChanged(alertCountdown) {
      this.alertCountdown = alertCountdown
    },
    resetForm() {
      this.form = {
        full_name: null,
        user_name: null,
        email: null,
        password: null,
        phone: null,
        approvement: null,
        image: null
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.uploadFile();
    },
    uploadFile() {
      if(this.form.image) {
        var formData = new FormData();
        for ( var key in this.form ) {
          if(this.form.image)
          formData.append(key, this.form[key]);
        }
        formData.append("file", this.form.image);
        formData.append("folder_name", "teachers");
        axios.post(process.env.VUE_APP_API_URL + '/admin/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          this.form.image = response.data;
          this.addTeacher();
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      }
      else{
        delete this.form.image;
        this.addTeacher();
      }
    },
    addTeacher() {
      axios.post(process.env.VUE_APP_API_URL + '/admin/teachers', this.form)
      .then((response) => {
        this.alertType = "success"
        this.alertText = "تم اضافة المدرس بنجاح "
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.resetForm();
      })
      .catch((error) => {
        var message = '';
        if(error.response.data.error.phone[0] == "The phone has already been taken.") {
          message = "رقم الهاتف مستخدم من قبل"
        }
        else if(error.response.data.error.email[0] == "The email has already been taken.") {
          message = "البريد الالكتروني مستخدم من قبل"
        }
        else if(error.response.data.error.user_name[0] == "The user name has already been taken.") {
          message = " اسم المستخدم مستخدم من قبل"
        }
        else{
          message = "خطأ .. حاول مرة اخرى لاحقا";
        }
        this.isLoading = false;
        this.alertType = "danger";
        this.alertText = message;
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
      });
    },
  }
};
</script>