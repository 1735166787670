const mutations  = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERNAME(state, user_name) {
    state.user_name = user_name;
  },
  SET_ACCOUNT_TYPE(state, account_type) {
    state.account_type = account_type;
  },
  LOGOUT(state) {
    state.token = null;
    state.user_name = null;
    state.account_type = null;
  },
}
export default mutations 
