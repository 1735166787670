<template>
  <div class="page-layout users">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3><b>إضافة فصل</b></h3>
    </div>
    <div class="text-center text-danger my-2" v-show="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <b-form @submit.stop.prevent="onSubmit" v-show="!isLoading">
      <b-container class="bv-example-row">
        <b-row>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-name" label="اسم الفصل" label-for="admin-add-name">
              <b-form-input
                id="admin-add-name"
                name="admin-add-name"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                aria-describedby="input-add-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-name">
                اسم الفصل مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-room-add-image" label="الصورة" label-for="room-add-image">
              <b-form-file
                type="file"
                id="room-add-image"
                name="room-add-image"
                v-model="$v.form.image.$model"
                :state="validateState('image')"
                aria-describedby="input-add-image"
              ></b-form-file>
              <b-form-invalid-feedback id="input-add-image">
                  الملف مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-subject" label="اسم المادة" label-for="admin-add-subject">
              <b-form-input
                id="admin-add-subject"
                name="admin-add-subject"
                v-model="$v.form.subject.$model"
                :state="validateState('subject')"
                aria-describedby="input-add-subject"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-subject"  v-if='!$v.form.subject.required'>
                اسم المادة مطلوبة
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-approvement" label="حالة الفصل" label-for="admin-add-approvement">
              <b-form-select
                id="admin-add-approvement"
                name="admin-add-approvement"
                v-model="$v.form.approvement.$model"
                :options="approvements"
                :state="validateState('approvement')"
                aria-describedby="input-add-approvement"
              ></b-form-select>
              <b-form-invalid-feedback id="input-add-approvement" v-if='!$v.form.approvement.required'>حالة الفصل مطلوبة</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <b-form-group label="المدرسين">
              <b-row>
                <b-col lg="4" md="12" v-for="(teacher, index) in teachers" :key="index">
                  <b-form-checkbox class="pt-3" v-model="form.teacher_id" :value="teacher.value" name="teachers" inline >
                    {{ teacher.text }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary">اضافة فصل</b-button>
        <b-button class="mr-2" @click="resetForm()">اعادة</b-button>
      </b-container>
      
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      alertCountdown: 0,
      alertType: '',
      alertText: '',
      approvements: [
        { value: null, text: "اختر..." },
        { value: 'accept', text: "مفتوح" },
        { value: 'under_revision', text: "تحت المراجعة" },
        { value: 'blocked', text: "مغلق" },
      ],
      form: {
        name: null,
        subject: null,
        approvement: null,
        teacher_id: [],
        block_reason: null,
        image: null
      },
      teachers: []
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      subject: {
        required
      },
      teacher_id: {
      },
      approvement: {
        required
      },
      image: {
      },
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    countDownChanged(alertCountdown) {
      this.alertCountdown = alertCountdown
    },
    uploadFile() {
      var formData = new FormData();
      if(this.form.image) {
        formData.append("file", this.form.image);
        formData.append("folder_name", "rooms");
        axios.post(process.env.VUE_APP_API_URL + '/admin/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          this.form.image = response.data;
          this.addData();
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      }
      else {
        delete this.form.image;
        this.addData();
      }
    },
    resetForm() {
      this.form = {
        name: null,
        subject: null,
        teacher_id: [],
        approvement: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.uploadFile();
    },
    addData() {
      this.form.is_private = 0;
      axios.post(process.env.VUE_APP_API_URL + '/admin/rooms', this.form)
      .then((response) => {
        this.alertType = "success"
        this.alertText = "تم اضافة الفصل بنجاح "
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.resetForm();
      })
      .catch((error) => {
        this.isLoading = false;
        this.alertType = "danger"
        this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
      });
    },
    loadTeachers() {
      this.isLoading = true;
      this.teachers = [];
      axios.get(process.env.VUE_APP_API_URL + '/admin/teachers', {})
      .then((response) => {
        if(response.data.data.length > 0){
          response.data.data.forEach(element => {
            this.teachers.push({
              text: element.full_name,
              value: element.id
            })
          });
          this.isLoading = false;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.alertType = "danger"
        this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.$router.push({
          name: 'rooms'
        })
      });
    }
  },
  created() {
    this.loadTeachers();
  }
};
</script>
<style scoped>
  .form-check-inline{
    width: 30% !important;
  }
</style>