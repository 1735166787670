<template>
  <div v-if="!['login'].includes($route.name)">
    <div class="header">
      <a href="#" class="nav-trigger" @click="navToogle">
        <span></span>
      </a>
      <div class="logoutBtn-div">
        <b-dropdown size="sm" id="dropdown-1" :text="username" class="mt-2 logoutBtn"  variant="primary">
          <b-dropdown-item v-b-modal.modal-logout>تسجيل الخروج</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="side-nav" :class="{ visible: isVisible }" v-if="!['NotFound'].includes($route.name)">
      <div class="logo">
        <span>لوحة الإعدادات</span>
      </div>
      <nav>
        <ul>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/">
              <i class="fa fa-home"></i>
              <span class="hideInSmall">الصفحة الرئيسية</span>
            </router-link>
          </li>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/admins" >
              <i class="fas fa-user-shield"></i>
              <span class="hideInSmall"> المسئولين</span>
            </router-link>
          </li>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/teachers" >
              <i class="fa fa-chalkboard-teacher"></i>
              <span class="hideInSmall"> المدرسين</span>
            </router-link>
          </li>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/students">
              <i class="fas fa-user-graduate"></i>
              <span class="hideInSmall"> الطلاب</span>
            </router-link>
          </li>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/rooms">
              <i class="fas fa-chalkboard"></i>
              <span class="hideInSmall"> الفصول</span>
            </router-link>  
          </li>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/rooms/join-requests">
              <i class="fas fa-user-plus"></i>
              <span class="hideInSmall"> طلبات الفصول</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div> 
    <b-modal id="modal-logout" title="تسجيل الخروج">
      <p class="my-4">هل تريد تسجيل الخروج?</p>
      <template v-slot:modal-footer>
      <b-button size="sm" variant="secondary" @click="$bvModal.hide('modal-logout')">
        الغاء
      </b-button>
      <b-button size="sm" variant="primary" @click="logout()">
        الخروج
      </b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import store from "../../store/index.js"
import { sendRequest }  from "../../utils/RequestHelper.js"

export default {
  data() {
    return {
      isVisible: false,
      username: null,
    };
  },
  components: {},
  watch: {
    $route(to, from) {
      this.username = this.$store.getters.username;
    }
  },
  methods: {
    navToogle() {
      this.isVisible = !this.isVisible;
    },
    logout() {
      var successCallback = () => {
        store.commit("LOGOUT");
        this.$router.push({
          name: "login"
        })
      };
      var errorCallback = () => {
      };
      sendRequest('post', '/admin/logout', true, {}, successCallback, errorCallback);
    }
  },
  created() {
    this.username = store.getters.username ? store.getters.username : 'مدرس'
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #d5dae5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 3;
  height: 50px;
  background-color: #fff;
  border-bottom: 3px solid #2d3d51;
}
.nav-link{
  padding: 0px;
}
/* logo in header(mobile version) and side-nav (tablet & desktop) */
.logo {
  height: 50px;
  padding: 10px;
  font-weight: 700;
}
.header .logo {
  color: #233245;
}
.side-nav .logo {
  background-color: #233245;
  color: #fff;
}
.header .logo {
  float: right;
}
.header .logo {
  height: 55px;
  z-index: 1;
  padding: 10px;
  font-weight: 700;
  color: #233245;
  margin-right: 40px;
}
.logo i {
  font-size: 22px;
}
.logo span {
  margin-top: 2px;
  margin-right: 5px;
  text-transform: uppercase;
}
.logoImg{
  width: 20px;
}
.nav-trigger {
  position: relative;
  float: right;
  width: 20px;
  height: 50px;
  right: 30px;
  display: block;
}
.nav-trigger span,
.nav-trigger span:before,
span:after {
  width: 20px;
  height: 2px;
  background-color: #35475e;
  position: absolute;
}
.nav-trigger span {
  top: 50%;
}
.nav-trigger span:before,
.nav-trigger span:after {
  content: "";
}
.nav-trigger span:before {
  top: -6px;
}
.nav-trigger span:after {
  top: 6px;
}
/* side navigation styles */
.side-nav {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #35475e;
  z-index: 1;
  display: none;
}
.side-nav.visible {
  display: block;
  position: fixed;
}
.side-nav ul {
  margin: 0;
  padding: 0;
}
.side-nav ul li {
  border-bottom: 1px solid #3c506a;
  position: relative;
}
.router-link-exact-active{
  border-right: white 4px solid
}
.side-nav ul li a{
  padding: 16px 16px;
}
.side-nav ul li.active:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #fff !important;
}
.router-link-exact-active:has(li){
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #fff !important;
}
.side-nav ul li a {
  color: #fff;
  display: block;
  text-decoration: none;
}
.side-nav ul li i {
  color: white;
  min-width: 20px;
  text-align: center;
}
.side-nav ul li span:nth-child(2) {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

/* main content styles */
.main-content {
  padding: 40px;
  margin-top: 0;
  padding: 0;
  padding-top: 50px;
  height: 100%;
}
.main-content .title {
  background-color: #eef1f7;
  border-bottom: 1px solid #b8bec9;
  padding: 10px 20px;
  font-weight: 700;
  color: #333;
  font-size: 18px;
}
.logoutBtn{
  float: left;
  color:red;
  margin-left: 10px;
}
.logoutBtn button{
  background-color: transparent;
}

.btn-group>.btn{
  background-color: transparent !important;
}
/* set element styles to fit tablet and higher(desktop) */
@media screen and (min-width: 600px) {
  .header {
    background-color: #35475e;
    z-index: 1;
    position: fixed;
  }
  .header .logo {
    display: none;
  }
  .nav-trigger {
    display: none;
  }
  .nav-trigger span,
  .nav-trigger span:before,
  span:after {
    background-color: #fff;
  }
  .side-nav {
    display: block;
    width: 70px;
    z-index: 2;
  }
  .side-nav ul li span:nth-child(2) {
    display: none;
  }
  .side-nav .logo i {
    padding-right: 12px;
  }
  .side-nav .logo span {
    display: none;
  }
  .side-nav ul li i {
    font-size: 26px;
  }
  .side-nav ul li a {
    text-align: center;
  }
  .main-content {
    margin-right: 70px;
  }   
  .btn-group>.btn{
    background-color: transparent !important;
  }
}
/* set element styles for desktop */
@media screen and (min-width: 1100px) {
  .side-nav {
    width: 15%;
  }
  .side-nav ul li span:nth-child(2) {
    display: inline-block;
  }
  .side-nav ul li i {
    font-size: 16px;
  }
  .side-nav ul li a {
    text-align: right;
  }
  .side-nav .logo i {
    padding-right: 0;
  }
  .side-nav .logo span {
    display: inline-block;
  }
  .main-content {
    margin-right: 200px;
  }
  .dropdown-toggle{
    color: white !important;
  }
  .btn-group>.btn{
    background-color: transparent !important;
  }
}
/* main box container */
.main {
  display: flex;
  flex-flow: row wrap;
}
.widget {
  flex-basis: 300px;
  flex-grow: 10;
  height: 300px;
  margin: 15px;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
}
.widget .title {
  background-color: #eef1f7;
  border-bottom: 1px solid #dfe4ec;
  padding: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #617085;
  font-weight: 600;
}
.ad {
  width: 350px;
  height: 300px;
  border: 1px solid #ddd;
}
.btn-group>.btn{
  background-color: transparent !important;
}
</style>