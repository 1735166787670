const getters  = {
  authToken(state) {
    return state.token
  },
  username(state) {
    return state.user_name
  },
  accoutType(state) {
    return state.account_type
  },
}
export default getters 
