<template>
  <div class="page-layout items">
     <b-alert
      :show="alertCountdown"
      dismissible
      fade
      :variant="alertType"
      @dismiss-count-down="countDownChanged"
    >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3><b>الفصول</b></h3>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <div class="">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" id="dataTables-example">
                <thead>
                  <tr>
                    <th width="5%">
                      <span class="tbl-data">#</span>
                    </th>
                    <th>
                      <span class="tbl-data">الصورة</span>
                    </th>
                    <th>
                      <span class="tbl-data">الاسم</span>
                    </th>
                    <th>
                      <span class="tbl-data">المادة</span>
                    </th>
                    <th>
                      <span class="tbl-data">حالة الفصل</span>
                    </th>
                    <th>
                      <span class="tbl-data">نوع الفصل</span>
                    </th>
                     <th>
                      <span class="tbl-data">المدرسين</span>
                    </th>
                    <th>
                      <span class="tbl-data">الدروس</span>
                    </th>
                     <th>
                      <span class="tbl-data">الملفات</span>
                    </th>
                    <th width="10%">
                      <span clas="tbl-data">
                        <b-button @click="goToRoute('addRoom')" size="sm" class="btn btn-success add-admin-btn options-admin-btn" data-toggle="modal" data-target="#addAdmin" ><i class="fa fa-plus"></i></b-button>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no-data-row" v-if="items.length == 0 && isLoading">
                    <td colspan="10">
                      <div class="text-center >text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </td>
                  </tr>
                  <tr class="no-data-row" v-if="items.length == 0 && !isLoading">
                    <td colspan="10">
                      لا يوجد معلومات
                    </td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      {{++index}}
                    </td>
                    <td>
                      <b-avatar :src="item.image" class="ml-3"></b-avatar>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.name}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.subject}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{approventDir[item.approvement]}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.is_private == 1? "خاصة": "عامة"}}</span>
                    </td>
                    <td>
                      <b-button type="button" size="sm" class="btn btn-primary options-admin-btn edit-options-admin-btn" @click="openTeachersModal(item.teachers_names)">المدرسين</b-button>
                    </td>
                    <td>
                      <div class="form-group row-option">
                        <b-button type="button" size="sm" class="btn btn-primary options-admin-btn edit-options-admin-btn" @click="goToEditLessons(item.id)">الدروس</b-button>
                      </div>
                    </td>
                    <td>
                      <div class="form-group row-option">
                        <b-button type="button" size="sm" class="btn btn-primary options-admin-btn edit-options-admin-btn" @click="goToEditFiles(item.id)">الملفات</b-button>
                      </div>
                    </td>
                    <td>
                      <div class="form-group row-option">
                        <b-button type="button" size="sm" class="btn btn-primary options-admin-btn edit-options-admin-btn" @click="goToEditRoute(item.id)"><i class="fa fa-edit"></i></b-button>
                        <b-button type="button" size="sm" class="btn btn-danger options-admin-btn del-options-admin-btn" data-toggle="modal" data-target="#delAdmin" @click="openDeleteItem(item.id);" ><i class="fa fa-trash"></i></b-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="delModal" centered title="حذف الفصل">
      <p>هل تريد حذف هذا الفصل ؟</p>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-left mr-1"  @click="deleteItem()">
            حذف
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="delModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="roomTeachers" size="lg" centered title="المدرسين">
      <div>
        <b-list-group>
          <b-row>
            <b-col lg="12" md="12" v-if="teachersModal.length == 0">
              <p style="text-align:center">لا يوجد مدرسين</p>
            </b-col>
            <b-col lg="4" md="12" v-for="(teacher, index) in teachersModal" :key="index">
              <b-list-group-item >{{teacher}}</b-list-group-item>
            </b-col>
          </b-row>
        </b-list-group>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="secondary" size="sm" class="float-left" @click="roomTeachers=false">
            اغلاق
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import axios from 'axios';

  export default {
    name: 'Home',
    data() {
      return {
        items:[],
        isLoading: true,
        alertType: '',
        alertText: '',
        alertCountdown: 0,
        showDismissibleAlert: false,
        approventDir: {
          "under_revision": "تحت المراجعة",
          "accept": "مفتوح",
          "blocked":"مغلق"
        },
        currentID: null,
        delModal: false,
        roomTeachers: false,
        teachers: [],
        teacherDic: {},
        teachersModal: []
      }
    },
    methods: {
      openTeachersModal(teachers) {
        teachers.forEach(teacher => {
          if(teacher != " " && teacher != null) {
            this.teachersModal.push(teacher)
          }
        });
        this.roomTeachers = true
      },
      loadData(){
        this.isLoading = true;
        axios.get(process.env.VUE_APP_API_URL + '/admin/rooms', {})
        .then((response) => {
          var data = response.data.data;
          this.items = data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      },
      delItem(item){
        this.delModal = true;
      },
      goToEditLessons(id) {
        this.$router.push({
          name: "roomLesson",
          params: {
            roomID: id
          }
        })
      },
      goToEditFiles(id) {
        this.$router.push({
          name: "roomFiles",
          params: {
            roomID: id
          }
        })
      },
      deleteItem() {
        if(this.currentID) {
          this.isLoading = true;
          axios.delete(process.env.VUE_APP_API_URL + '/admin/rooms/' + this.currentID, {
          })
          .then((response) => {
            this.alertType = "success"
            this.alertText = "تم حدف الفصل بنجاح "
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.delModal = false;
            this.loadData();
          })
          .catch((error) => {
            this.isLoading = false;
            this.alertType = "danger"
            this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.delModal = false;
          });
        }
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      openDeleteItem(id){
        if(id) {
          this.delModal = true;
          this.currentID = id;
        }
      },
      goToEditRoute(id){
        if(id) {
          this.$router.push({
            name: 'editRoom',
            params: {
              id
            }
          })
        }
      },
      goToRoute(routeName) {
        this.$router.push({
          name: routeName
        })
      },
      loadTeachers() {
        this.isLoading = true;
        axios.get(process.env.VUE_APP_API_URL + '/admin/teachers', {})
        .then((response) => {
          this.teachers = response.data.data;
          if(this.teachers.length > 0)
          this.teachers.forEach(element => {
            if(!this.teacherDic[element.id])
              this.teacherDic[element.id] = element.full_name;
          });
          this.loadData();
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      }
    },
    created() {
      this.loadTeachers();
    },
  }
</script>
<style scoped>
  @import '../../../assets/css/tableContent.css'
</style>