<template>
  <div class="page-layout users">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="page-header" v-show="!isLoading">
      <h3><b>تعديل طالب</b><b-avatar :src="currentImage" class="mr-3 profile-image"></b-avatar></h3>
    </div>
    <div class="text-center text-danger my-2" v-show="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <b-form @submit.stop.prevent="onSubmit"  v-show="!isLoading">
      <b-container class="bv-example-row">
        <b-row>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-full-name" label="الاسم" label-for="admin-add-full-name">
              <b-form-input
                id="admin-add-full-name"
                name="admin-add-full-name"
                v-model="$v.form.full_name.$model"
                :state="validateState('full_name')"
                aria-describedby="input-add-full-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-full-name">
                الاسم مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-image" label="الصورة" label-for="admin-add-image">
              <b-form-file
                type="file"
                id="admin-add-image"
                name="admin-add-image"
                v-model="$v.form.image.$model"
                :state="validateState('image')"
                aria-describedby="input-add-image"
              ></b-form-file>
              <b-form-invalid-feedback id="input-add-image">
                  الصورة مطلوبة
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-user-name" label="اسم المستخدم" label-for="admin-add-user-name">
              <b-form-input
                id="admin-add-user-name"
                name="admin-add-user-name"
                v-model="$v.form.user_name.$model"
                :state="validateState('user_name')"
                aria-describedby="input-add-user-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-user-name"  v-if='!$v.form.user_name.required'>
                اسم المستخدم مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-email" label="البريد الاكتروني" label-for="admin-add-email">
              <b-form-input
                id="admin-add-email"
                name="admin-add-email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="input-add-email"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-email" v-if='!$v.form.email.required'>
                البريد الالكتروني مطلوب
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-email" v-if='!$v.form.email.email'>
                البريد الالكتروني غير صالح - xxx@yyy.zzz
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-student-add-password" label="كلمة المرور" label-for="student-add-password">
              <b-form-input
                id="student-add-password"
                name="student-add-password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="input-add-password"
                type="password"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-password" v-if='!$v.form.password.minLength'>
                كلمة المرور اكثر من 8 احرف
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-level" label="المرحلة الدراسية" label-for="admin-add-level">
              <b-form-select
                id="admin-add-level"
                name="admin-add-level"
                v-model="$v.form.level.$model"
                :options="levels"
                :state="validateState('level')"
                aria-describedby="input-add-level"
              ></b-form-select>
              <b-form-invalid-feedback id="input-add-level" v-if='!$v.form.level.required'> المرحلة الدراسية مطلوبة</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-phone" label="رقم المحمول" label-for="admin-add-phone">
              <b-form-input
                id="admin-add-phone"
                name="admin-add-phone"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                aria-describedby="input-add-phone"
                type="tel"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.required'>
                  رقم المحمول مطلوب
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.numeric'>
                  رقم المحمول غير صالح - يجب ان يحتوي على ارقام فقط
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.minLength'>
                  رقم المحمول غير صالح - يتكون من 11 رقما
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-student-add-parent_phone" label="رقم محمول الوالد" label-for="student-add-parent_phone">
              <b-form-input
                id="student-add-parent_phone"
                name="student-add-parent_phone"
                v-model="$v.form.parent_phone.$model"
                :state="validateState('parent_phone')"
                aria-describedby="input-add-phone"
                type="tel"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-parent_phone" v-if='!$v.form.parent_phone.required'>
                  رقم محمول الوالد مطلوب
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-parent_phone" v-if='!$v.form.parent_phone.numeric'>
                  رقم المحمول غير صالح - يجب ان يحتوي على ارقام فقط
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-parent_phone" v-if='!$v.form.parent_phone.minLength || !$v.form.parent_phone.maxLength'>
                  رقم المحمول غير صالح - يتكون من 11 رقما
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-approvement" label="حالة الحساب" label-for="admin-add-approvement">
              <b-form-select
                id="admin-add-approvement"
                name="admin-add-approvement"
                v-model="$v.form.approvement.$model"
                :options="approvements"
                :state="validateState('approvement')"
                aria-describedby="input-add-approvement"
              ></b-form-select>
              <b-form-invalid-feedback id="input-add-approvement" v-if='!$v.form.approvement.required'>حالة الحساب مطلوبة</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary">تعديل</b-button>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  mixins: [validationMixin],
  data() {
    return {
      alertCountdown: 0,
      alertType: '',
      alertText: '',
      approvements: [
        { value: null, text: "اختر..." },
        { value: 'accept', text: "مفتوح" },
        { value: 'under_revision', text: "تحت المراجعة" },
        { value: 'blocked', text: "مغلق" },
      ],
      levels: [
        { value: null, text: "اختر..." },
        { value: 'kg1', text: "KG 1" },
        { value: 'kg2', text: "KG 2" },
        { value: 'primary', text: "الابتدائية" },
        { value: 'preparatory', text: "الاعدادية" },
        { value: 'secondary', text: "الثانوية" },
        { value: 'university', text: "طالب جامعى" },
      ],
      form: {
        full_name: null,
        user_name: null,
        email: null,
        phone: null,
        parent_phone: null,
        approvement: null,
        level: null,
        block_reason: null,
        image: null,
        password: null,
      },
      currentImage: null
    };
  },
  validations: {
    form: {
      full_name: {
        required
      },
      user_name: {
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
      parent_phone: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
      approvement: {
        required
      },
      level: {
        required
      },
      image: {
      },
      password: {
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    countDownChanged(alertCountdown) {
      this.alertCountdown = alertCountdown
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      this.uploadFile();
    },
    uploadFile() {
      if(this.form.image) {
        var formData = new FormData();
        formData.append("file", this.form.image);
        formData.append("folder_name", "students");
        axios.post(process.env.VUE_APP_API_URL + '/admin/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          this.form.image = response.data;
          this.saveData();
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      }
      else {
        delete this.form.image;
        this.saveData();
      }
    },
    saveData() {
      if(this.form.password == null) 
        delete this.form.password
      this.form.id = this.$route.params.id;
      axios.put(process.env.VUE_APP_API_URL + '/admin/students/' + this.$route.params.id, this.form)
      .then((response) => {
        this.isLoading = true;
        this.alertType = "success"
        this.alertText = "تم تعديل بيانات الطالب بنجاح "
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.loadData();
      })
      .catch((error) => {
        this.isLoading = false;
        this.alertType = "danger"
        this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
      });
    },
    loadData() {
      axios.get(process.env.VUE_APP_API_URL + '/admin/students/' + this.$route.params.id, this.form)
      .then((response) => {
        this.isLoading = false;
        this.form = response.data.data;
        this.currentImage = this.form.image;
        this.form.image = null;
        this.form.password = null;
      })
      .catch((error) => {
        this.isLoading = false;
        this.alertType = "danger"
        this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.$router.push({
          name: 'students'
        })
      });
    }
  },
  created() {
    this.isLoading = true;
    this.loadData();
  }
};
</script>
<style scoped>
  .profile-image{
    width: 50px !important;
    height: 50px !important;
   }
</style>
