<template>
  <div class="page-layout users">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3><b>إضافة مسئول</b></h3>
    </div>
    <b-form @submit.stop.prevent="onSubmit">
      <b-container class="bv-example-row">
        <b-row>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-full-name" label="الاسم" label-for="admin-add-full-name">
              <b-form-input
                id="admin-add-full-name"
                name="admin-add-full-name"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                aria-describedby="input-add-full-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-full-name">
                الاسم مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-user-name" label="اسم المستخدم" label-for="admin-add-user-name">
              <b-form-input
                id="admin-add-user-name"
                name="admin-add-user-name"
                v-model="$v.form.user_name.$model"
                :state="validateState('user_name')"
                aria-describedby="input-add-user-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-user-name"  v-if='!$v.form.user_name.required'>
                اسم المستخدم مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-email" label="البريد الاكتروني" label-for="admin-add-email">
              <b-form-input
                id="admin-add-email"
                name="admin-add-email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="input-add-email"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-email" v-if='!$v.form.email.required'>
                البريد الالكتروني مطلوب
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-email" v-if='!$v.form.email.email'>
                البريد الالكتروني غير صالح - xxx@yyy.zzz
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-password" label="كلمة المرور" label-for="admin-add-password">
              <b-form-input
                id="admin-add-password"
                name="admin-add-password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="input-add-password"
                type="password"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-password" v-if='!$v.form.password.required'>
                كلمة المرور مطلوبه
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-password" v-if='!$v.form.password.minLength'>
                كلمة المرور اكثر من 8 احرف
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-phone" label="رقم المحمول" label-for="admin-add-phone">
              <b-form-input
                id="admin-add-phone"
                name="admin-add-phone"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                aria-describedby="input-add-phone"
                type="tel"
              ></b-form-input>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.required'>
                  رقم المحمول مطلوب
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.numeric'>
                  رقم المحمول غير صالح - يجب ان يحتوي على ارقام فقط
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.minLength'>
                  رقم المحمول غير صالح - يتكون من 11 رقما
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-add-role" label="المسئولية" label-for="admin-add-role">
              <b-form-select
                id="admin-add-role"
                name="admin-add-role"
                v-model="$v.form.role.$model"
                :options="roles"
                :state="validateState('role')"
                aria-describedby="input-add-role"
              ></b-form-select>
              <b-form-invalid-feedback id="input-add-role" v-if='!$v.form.role.required'>المسئولية مطلوبة</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary">اضافة مستخدم</b-button>
        <b-button class="mr-2" @click="resetForm()">اعادة</b-button>
      </b-container>
      
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  mixins: [validationMixin],
  data() {
    return {
      alertCountdown: 0,
      alertType: '',
      alertText: '',
      roles: [
        { value: null, text: "اختر..." },
        { value: 1, text: "موظف" },
        { value: 2, text: "مدير" }
      ],
      form: {
        name: null,
        user_name: null,
        email: null,
        password: null,
        phone: null,
        role: null
      }
    };
  },
  validations: {
    form: {
      name: {
        required
      },
      user_name: {
        required
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      phone: {
        required,
        numeric,
        minLength: minLength(11)
      },
      role: {
        required
      },
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    countDownChanged(alertCountdown) {
      this.alertCountdown = alertCountdown
    },
    resetForm() {
      this.form = {
        name: null,
        user_name: null,
        email: null,
        password: null,
        phone: null,
        role: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.addAdmin();
    },
    addAdmin() {
      axios.post(process.env.VUE_APP_API_URL + '/admin/admins', this.form)
      .then((response) => {
        this.alertType = "success"
        this.alertText = "تم اضافة المستخدم بنجاح "
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.resetForm();
      })
      .catch((error) => {
        this.isLoading = false;
        this.alertType = "danger"
        this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
      });
    },
  }
};
</script>