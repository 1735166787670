import { render, staticRenderFns } from "./EditTeacher.vue?vue&type=template&id=68bc0392&scoped=true"
import script from "./EditTeacher.vue?vue&type=script&lang=js"
export * from "./EditTeacher.vue?vue&type=script&lang=js"
import style0 from "./EditTeacher.vue?vue&type=style&index=0&id=68bc0392&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68bc0392",
  null
  
)

export default component.exports