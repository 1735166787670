<template>
  <div class="login-page">
    <div class="container">
      <div class="panel panel-default login-panel">
        <div>
          <b-row>
            <b-col lg="12" md="12">
              <div class="panel-heading">
                <h4><strong>ادخل البيانات لتسجيل الدخول</strong></h4>
                
              </div>
            </b-col>
          </b-row><br><br><br>
          <admin-login></admin-login>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import adminLogin from "../components/adminLogin.vue"

  export default {
    components: {
      adminLogin,
    }
  }
</script>

<style>
  .login-page{
    width: 100% !important;
  }
  .login-panel{
    width: 40%;
    margin: auto;
  }
  .panel-heading{
    text-align: center;
  }
  .form-footer{
    padding-top: 20px;
  }
  .login-btn{
    display: block !important;
    margin: auto !important;
  }
  @media (max-width: 950px) {
    .login-panel{
      width: 70%;
      margin: auto;
    }
  }
  @media (max-width: 650px) {
    .login-panel{
      width: 100%;
      margin: auto;
    }
    .pageContent{
      padding: 30px 5px !important;
    }
  }
</style>