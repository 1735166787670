import { render, staticRenderFns } from "./RoomLessons.vue?vue&type=template&id=147ee230&scoped=true"
import script from "./RoomLessons.vue?vue&type=script&lang=js"
export * from "./RoomLessons.vue?vue&type=script&lang=js"
import style0 from "./RoomLessons.vue?vue&type=style&index=0&id=147ee230&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147ee230",
  null
  
)

export default component.exports