import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

// pages
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'

// teachers
import Teachers from '../views/admin/teachers/Teachers.vue'
import AddTeacher from '../views/admin/teachers/AddTeacher.vue'
import EditTeacher from '../views/admin/teachers/EditTeacher.vue'

// students
import Students from '../views/admin/students/Students.vue'
import AddStudent from '../views/admin/students/AddStudent.vue'
import EditStudent from '../views/admin/students/EditStudent.vue'

// rooms
import Rooms from '../views/admin/rooms/Rooms.vue'
import AddRoom from '../views/admin/rooms/AddRoom.vue'
import EditRoom from '../views/admin/rooms/EditRoom.vue'
import JoinRoomRequest from '../views/admin/rooms/Requests.vue'

// roomLesson
import RoomLessons from '../views/admin/roomProfile/RoomLessons.vue'
import RoomFiles from '../views/admin/roomProfile/RoomFiles.vue'

// admins
import Admins from '../views/admin/admins/Admins.vue'
import AddAdmin from '../views/admin/admins/AddAdmin.vue'
import EditAdmin from '../views/admin/admins/EditAdmin.vue'



import routeAuth from "../utils/RoutingHelper.js"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/teachers',
    name: 'teachers',
    component: Teachers,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/add-teacher',
    name: 'addTeacher',
    component: AddTeacher,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/edit-teacher/:id',
    name: 'editTeacher',
    component: EditTeacher,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/students',
    name: 'students',
    component: Students,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/add-student',
    name: 'addStudent',
    component: AddStudent,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/edit-student/:id',
    name: 'editStudent',
    component: EditStudent,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: Rooms,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/add-room',
    name: 'addRoom',
    component: AddRoom,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/edit-room/:id',
    name: 'editRoom',
    component: EditRoom,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/room/:roomID/lessons',
    name: 'roomLesson',
    component: RoomLessons,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/room/:roomID/files',
    name: 'roomFiles',
    component: RoomFiles,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/admins',
    name: 'admins',
    component: Admins,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/add-admin',
    name: 'addAdmin',
    component: AddAdmin,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/edit-admin/:id',
    name: 'editAdmin',
    component: EditAdmin,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '/rooms/join-requests',
    name: 'joinRoomRequest',
    component: JoinRoomRequest,
    beforeEnter: routeAuth.isAdminLoggedIn,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    beforeEnter: routeAuth.isLoggedIn,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
