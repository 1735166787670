<template>
  <div>
    <b-form @submit.stop.prevent="onSubmit">
      <b-container class="bv-example-row">
        <b-row>
          <b-col lg="12" md="12" class="pb-2">
            <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
              {{alertText}}
            </b-alert>
            <br><br><br><br>
          </b-col>
          <br><br>
          <b-col lg="12" md="12">
            <b-form-group id="group-admin-user_name" label="اسم المستخدم او البريد الالكتروني" label-for="admin-user_name">
              <b-form-input
                id="admin-user_name"
                name="admin-user_name"
                v-model="$v.form.user_name.$model"
                :state="validateState('user_name')"
                aria-describedby="input-user_name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-user_name">
                اسم المستخدم مطلوب
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <b-form-group id="group-admin-password" label="كلمة المرور" label-for="admin-password">
              <b-form-input
                id="admin-password"
                name="admin-password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="input-password"
                type="password"
              ></b-form-input>
              <b-form-invalid-feedback id="input-password" v-if='!$v.form.password.required'>
                كلمة المرور مطلوبه
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <div class="form-footer">
              <b-button class="login-btn" type="submit" variant="primary"> تسجيل الدخول</b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
  import store from "../store/index.js"
  import { validationMixin } from "vuelidate";
  import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
  import axios from 'axios';

  export default {
    mixins: [validationMixin],
    data() {
      return {
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        form: {
          user_name: null,
          password: null,
        }
      }
    },
    validations: {
      form: {
        user_name: {
          required
        },
        password: {
          required,
        },
      }
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.login();
      },
      login() {
        axios.post(process.env.VUE_APP_API_URL + '/admin/login', this.form)
        .then((response) => {
          this.alertType = "success"
          this.alertText = "تم  تسجيل الدخول بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          store.commit("SET_TOKEN", response.data.data.token);
          store.dispatch("SET_USERNAME", response.data.data.user_name);
          store.dispatch("SET_ACCOUNT_TYPE", "admin");
          this.$router.push({
            name: 'home'
          })
        })
        .catch((error) => {
          if(error.response.status == 422) {
            this.alertType = "danger"
            this.alertText = error.response.data.error
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          }
        });
      },
    },
    created() {
      if(store.getters.authToken) {
        this.$router.push({
          name: 'home'
        })
      }
    }
  }
</script>

<style>
  .login-page{
    width: 100% !important;
  }
  .login-panel{
    width: 35%;
    margin: auto;
  }
  .panel-heading{
    text-align: center;
  }
  .form-footer{
    padding-top: 20px;
  }
  .login-btn{
    display: block !important;
    margin: auto !important;
  }
</style>